/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0b1 | 201101 
   NOTE: WORK IN PROGRESS
   USE WITH CAUTION AND TEST WITH ABANDON */
 
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video, * {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1.3;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
 
/* remember to highlight inserts somehow! */
ins {
    text-decoration: none;
}
del {
    text-decoration: line-through;
}
 
table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* :::::::::::::::::::::::::::::::::::::::: */
/*
@import url(https://fonts.googleapis.com/css?family=Roboto:400,300,700,500);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,300,500,700);
*/
@import url(https://fonts.googleapis.com/css?family=Lobster|Oswald|Open+Sans:400,300,700);

/* STYLING ************************************/
    body, * {
        font-family: 'Open Sans', sans-serif;
        box-sizing: border-box;
   }
    h2,h3,h4,h5,h6,
    footer {
        font-family: 'Oswald', sans-serif;
        color: #C7C9CE;
    }
    footer h4 {
        font-size: 15px;
        text-transform: uppercase;
        border-bottom: 2px solid #C7C9CE;
        padding: 0 0 .5rem;
        margin: 0 0 .5rem;
    }
    h1::first-letter {
        /*font-size: 60px;
        font-weight: 600;
        color:#c90000;*/
    }

    .content h1 {
        font-size: 40px;
        color: #20283A;
        font-family: 'Lobster', serif;
        font-weight: normal;
       /*margin: 30px auto;*/
        /*text-shadow: 1px 1px 0px #BDB9B7;*/
        text-shadow: 1px 1px 2px rgba(0,0,0,.2);
        text-align: center;
    }
        .content_13 h1 {
            font-size: 30px;
        }
    a {
        color: #2b72bb;
        text-decoration: none;
    }
.anchor{
  display: block;
  height: 144px; /*same height as header*/
  margin-top: -144px; /*same height as header*/
  visibility: hidden;
} 
    .content p {
        font-size:17px;
        line-height: 140%;
        color: #666;
        font-family: 'Open Sans', sans-serif;
        font-weight: 300;
        margin: 1rem auto;
    }

        p.print_link {
            text-align: center;
            background: url(/images/printer_icon.png) no-repeat center;
            margin: 0;
            padding: 2rem 0;
        }
        p.print_link a {
            text-decoration: none;
            font-weight: bold;
        }
    footer a {
        color: #B6AFA0;
        font-weight: 300;
        text-decoration: none;
        letter-spacing: .5px; 
    }
    footer a:before {
      content: "» \0000a0";
    }
    footer span {
        font-weight: 300;
        letter-spacing: .5px; 
    }
    footer #credits {
        font-size: .75rem;
        font-family: 'Open Sans', sans-serif;
    }
    footer #credits a:before {
      content: "";
    }

    strong {
        font-weight: bold;
    }

    .sold_sign {
        color: #AD2333;
        font-weight: 700;
    }

    .right {
        float: right;
    }
    .left {
        float: left;
    }
    .center {
        text-align: center;
        margin: 0 auto;
    }

    .block_left,
    .block_right {
        display: table-cell;
        vertical-align: middle;
    }
    .block_left {
        width: 35%;
        text-align: right;
        padding: 0 2% 0 10%;
    }
    .block_right {
        width: 65%;
        border-left: 1px solid #ccc;
        padding: 0 10% 0 2%;
    }




/* STRUCTURE ************************************/
    header {
        width: 100%;
        position: fixed;
        top:0;
        left:0;
        background: rgba(255,255,255,1);
        z-index: 100;
        -webkit-box-shadow:0px 0px 20px rgba(44,44,44,.3);
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    }
    header #logo {
        width: 302px;
        margin: 1rem auto 0;
    }
        #print_logo {
            display: none;
        }

    header #logo img {
        height: 100%;
        width: 100%;
        
    }

    /*nav {
        display: inline-block;
    }*/
    /*
    #contact_block {
        position: fixed;
        top: 6px;
        right: 40px;
        background: url(http://library.owu.edu/images/phoneSmall.png) no-repeat left;
        padding-left: 30px;
    }
    */
     .content {
        /*height: 350px;*/
        width:100%;
        background: rgba(255,255,255,1);
        z-index: 2;
        padding: 1rem 0 0;
        /*background: url(../images/bg-texture-1.jpg);*/
        border-top: 2px solid #20283a;
        border-bottom: 2px solid #20283a;
    }
    #content_1,
    #content_2,
    #content_3 {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,eaeaea+10,eaeaea+100&0+0,0.3+10,0.5+100 */
        background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(234,234,234,0.3) 10%, rgba(234,234,234,0.5) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(234,234,234,0.3) 10%,rgba(234,234,234,0.5) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(234,234,234,0.3) 10%,rgba(234,234,234,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#80eaeaea',GradientType=0 ); /* IE6-9 */        
    }
    #content_3.content {
        /*border-top: 2px solid #000;*/
    }
    .content_container {
      padding: 2rem 0 6rem;
      width: 80%;
      margin: 0 auto;
    }
        @media screen and (min-width: 200px) and (max-width: 850px){
            .content_container {
              width: 90%;
            }
                 .content {
                    /*height: 350px;*/
                    width:100%;
                    background: rgba(255,255,255,1);
                    z-index: 2;
                    padding: 1rem 0 0;
                    /*background: url(../images/bg-texture-1.jpg);*/
                    border-top: 1px solid #aaa;
                    border-bottom: 1px solid #aaa;
                }
        }
        @media screen and (min-width: 1400px){
            .content_container {
              width: 60%;
            }
        }
    #content_1 {position: relative;}
    footer.content {
        background-image: none;
        background-color: #20283A;
    }
    footer #credits {
        width: 100%;
        text-align: center;
        border-top: 1px solid rgba(255,255,255,.25);
        padding: .5rem 0 6rem;
    }

/* RESPONSIVE  ************************************/
    .content_13 {
        width: 32%;
        padding: 0 2%;
        display: inline-block;
        vertical-align: top;
    }
    .content_23 {
        width: 64%;
        padding: 0 2%;
        display: inline-block;
        vertical-align: top;
    }


        .content_23 {
            width: 66%;
            padding-right:5%;
            display: inline-block;
            /*background: #ccc;*/
        }
        .content_13 {
            width: 32%;
            display: inline-block;
            /*background: #999;*/
        }
        @media screen and (max-width: 1000px){
            .content_23,
            .content_13 {
                width: 100%;
                padding: 1rem 5%;
            }
            #content_2 .content_13 {
                margin: 0 auto;
                display: block;
            }
            #content_2 .content_13:first-of-type {
                text-align: center;
            }
            #content_2 .content_13 {
                padding: 0;
            }
            #content_2 .content_13:first-of-type {
                padding: 1rem 0;
            }
            #content_2 .content_13 p {
                margin: 0 auto;
            }
            #content_3 .content_13.address {
                text-align: center;
            }

        }



/* PARALLAX  ************************************/


    @media screen and (min-width: 1280px){
        #wall_1 {
            background: url(../images/para/141_2000.jpg) no-repeat;
        }
        #wall_2 {
            background: url(../images/para/48_2000.jpg) no-repeat;
        }
        #wall_3 {
            background: url(../images/para/136_2000.jpg) no-repeat;
        }
    }
    @media screen and (min-width: 800px) and (max-width: 1279px){
        #wall_1 {
            background: url(../images/para/141_2000.jpg) no-repeat;
        }
        #wall_2 {
            background: url(../images/para/48_2000.jpg) no-repeat;
        }
        #wall_3 {
            background: url(../images/para/136_2000.jpg) no-repeat;
        }
    }
    @media screen and (min-width: 680px) and (max-width: 799px){
        #wall_1 {
            background: url(../images/para/141_2000.jpg) no-repeat;
        }
        #wall_2 {
            background: url(../images/para/48_2000.jpg) no-repeat;
        }
        #wall_3 {
            background: url(../images/para/136_2000.jpg) no-repeat;
        }
    }
    @media screen and (max-width: 679px){
        #wall_1 {
            background: url(../images/para/141_2000.jpg) no-repeat;
        }
        #wall_2 {
            background: url(../images/para/48_2000.jpg) no-repeat;
        }
        #wall_3 {
            background: url(../images/para/136_2000.jpg) no-repeat;
        }
    }


    .image {
        width: 100%;
        background: #55e6c5;
        z-index: 1;
        background-position: center 0;
    }
    #wall_1.image {
        height: 680px;
        background-attachment: fixed;
        background-size: cover;
        background-position: center 0 !important;
    }
    #wall_2.image {
        height: 420px;
        background-attachment: fixed;
        background-size: cover;
        background-position: center 0 !important;
    }
    #wall_3.image {
        height: 420px;
        background-attachment: fixed;
        background-size: cover;
        background-position: center 0;
    }

    @media only screen and (min-device-width: 320px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) { 
        #wall_1.image,
        #wall_2.image,
        #wall_3.image {
            display: none;
        }
        #content_1 {
            padding-top: 120px;
        }
    }

    @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) { 

    }
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
         #wall_1.image {
            height: 480px;
            background-size: contain;
        }
         #wall_2.image,
         #wall_3.image,
         #wall_2,
         #wall_3 {
            display: none;
        }
}

/* PROMO ************************************/

    #promo_msg {
        width: 65%;
/*        border: 1px solid rgba(255, 255, 255, 1.0);*/
        position: relative;
        top: 50%;
        left: 17.5%;


        @media screen and (min-width: 360px) {
        
        }
        @media screen and (min-width: 600px) {		
        	top: 40%;
        }
        @media screen and (min-width: 1000px) {
        
        }
        @media screen and (min-width: 1200px) {
        	left: 25%;
        	width: 50%;
        }
        @media screen and (min-width: 1400px) {
        
        }
        @media screen and (min-width: 1600px) {
        	left: 28.5%;
        	width: 43%;
        }
        @media screen and (min-width: 1900px) {
        
        }
        @media screen and (min-width: 2200px) {
        
        }        
    }
        #promo_msg_inner {
            background: rgba(191, 166, 134, 0.3);
            border: 2px solid rgba(255, 255, 255, 1.0);
            margin: .15rem;        
            padding: .75rem 1.5rem 2.25rem;


            @media screen and (min-width: 360px) {
            
            }
            @media screen and (min-width: 600px) {		
            	padding-bottom: 3.25rem;
            }
            @media screen and (min-width: 1000px) {
            
            }
            @media screen and (min-width: 1200px) {
            
            }
            @media screen and (min-width: 1400px) {
            	padding: 1rem 4rem 3.25rem;
            }
            @media screen and (min-width: 1600px) {
            	padding: 1rem 5rem 3.25rem;            
            }
            @media screen and (min-width: 1900px) {
            
            }
            @media screen and (min-width: 2200px) {
            
            }            
        }
            #promo_msg_inner h4 {
                font-size: 40px;
                text-shadow: 2px 2px 1px #000;
                color: #fff;
                text-align: center;
                overflow: hidden;
                padding: 1rem 0;
            }
            #promo_msg_inner h4 span {
                font-family: 'Lobster', serif !important;
                font-weight: 300 !important;
            }
            #promo_msg_inner p {
                color: #fff;
                text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
                font-family: 'Oswald', sans-serif !important;
                font-size: 18px;  
                line-height: 140%;  
                letter-spacing: .5px;   

				@media screen and (min-width: 360px) {

				}
				@media screen and (min-width: 600px) {		
					font-size: 20px;
				}
				@media screen and (min-width: 1000px) {

				}
				@media screen and (min-width: 1200px) {

				}
				@media screen and (min-width: 1400px) {

				}
				@media screen and (min-width: 1600px) {

				}
				@media screen and (min-width: 1900px) {

				}
				@media screen and (min-width: 2200px) {

				}                         
            }
        #three_panes {
            display: block;
            margin: 0 auto;
            position: absolute;
            text-align: center;
            width: 100%;
        }
            #three_panes div {
                display: inline-block;
                width: 130px;
                height: 110px;
                padding: 1px;
                margin: -30px 13px;
                border: 2px solid #fff;
                box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
            }
            #pane_one {
                background: url(../images/pane_one.jpg) no-repeat #fff;
            }
            #pane_two {
                background: url(../images/pane_two.jpg) no-repeat #fff;
            }
            #pane_three {
                background: url(../images/pane_three.jpg) no-repeat #fff;
            }

        @media all and (max-width: 768px), only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 1024px), only screen and (min--moz-device-pixel-ratio: 2) and (max-width: 1024px), only screen and (-o-min-device-pixel-ratio: 2/1) and (max-width: 1024px), only screen and (min-device-pixel-ratio: 2) and (max-width: 1024px), only screen and (min-resolution: 192dpi) and (max-width: 1024px), only screen and (min-resolution: 2dppx) and (max-width: 1024px) {
            #pane_three {
                display: none !important;
            }
        }
        @media all and (max-width: 550px), only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 767px), only screen and (min--moz-device-pixel-ratio: 2) and (max-width: 767px), only screen and (-o-min-device-pixel-ratio: 2/1) and (max-width: 1024px), only screen and (min-device-pixel-ratio: 2) and (max-width: 767px), only screen and (min-resolution: 192dpi) and (max-width: 767px), only screen and (min-resolution: 2dppx) and (max-width: 767px) {
            #pane_three,
            #pane_two,
            #pane_one {
                display: none !important;
            }
        }
        @media screen and (min-width: 200px) and (max-width: 550px){
            #promo_msg {
                width: 86%;
                border: 1px solid rgba(255, 255, 255, 1.0);
                position: relative;
                top: 35%;
                left: 7%;
            }
        }
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
                 #three_panes {
                    display: none;
                }
        }


/* BADGES ************************************/
.badges {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;

	width: 100%;
    box-sizing: border-box;
}
    .badges img {
    	flex-basis: 51%;
    	padding: .5rem 1rem 1rem;
	    box-sizing: border-box;
    }


@media screen and (min-width: 360px) {

}
@media screen and (min-width: 600px) {		
	.badges {
		flex-wrap: nowrap;
		justify-content: center;
	}
		.badges img {
			flex-basis: 17%;
		}
}
@media screen and (min-width: 1000px) {
	.badges {
		flex-wrap: nowrap;
		justify-content: center;
	}
		.badges img {
			flex-basis: 17%;
			padding: .75rem 3%;
		}
}
@media screen and (min-width: 1200px) {

}
@media screen and (min-width: 1400px) {

}
@media screen and (min-width: 1600px) {

}
@media screen and (min-width: 1900px) {

}
@media screen and (min-width: 2200px) {

}


/* LINES ON EITHER SIDE OF PROMO MESSASGE ************************************/
    .fancy {
      text-align: center;
    }
    .fancy span {
      display: inline-block;
      position: relative;  
    }
    .fancy span:before,
    .fancy span:after {
      content: "";
      position: absolute;
      /*height: 5px;*/
      /*border-bottom: 1px solid white;*/
      border-top: 2px solid white;
      box-shadow: 2px 2px 2px #333;
      top: 24px;
      width: 180px;
    }
    .fancy span:before {
      right: 100%;
      margin-right: 15px;
    }
    .fancy span:after {
      left: 100%;
      margin-left: 15px;
}

/* CONTACT FORM ************************************/
    #registration_form {
        margin: 30px 0 10px;
    }
    #registration_form input,
    #registration_form textarea,
    #registration_form .field_input {
        width: 100%;
        padding: .6rem 1rem .4rem .6rem;
        font-size: 1rem;
        font-family: helvetica;
        margin: .4rem 0;
        border-radius: 4px;
        background-color: #fafafa;
        border: 1px solid #ccc;
    }
    #registration_form input:focus,
    #registration_form textarea:focus,
    #registration_form .field_input:focus {
        border: 1px solid #999;
        background-color: #fff;
    }

    #registration_form textarea {
        height: 6rem;
    }
    #registration_form input,
    #registration_form label {
        display: block;
    }
    #registration_form input.submit {
        font-size: 1rem;
        font-family: helvetica;
        border-radius: 4px;
        display: inline;
        text-align: center;
        background-color: #468C00;
        color: #fff;
    }
    #registration_form input.submit:hover{
        cursor: hand; 
        cursor: pointer;
        box-shadow: 0px 0px 5px rgba(185,185,185,1);
        color: #ccc;
    }
    #registration_form .dude{
        display: none;
    }
/* EMAIL OPTIN FORM ************************************/
    form.icpsignup input {
        width: 78%;
        padding: .6rem 1rem .4rem .6rem;
        font-size: 1rem;
        font-family: helvetica;
        margin: .4rem 0;
        border-radius: 4px;
        display: inline;
    }
    form.icpsignup input.submit {
        width: 20%;
        font-size: 1rem;
        font-family: helvetica;
        border-radius: 4px;
        display: inline;
        text-align: center;
        background-color: #468C00;
        color: #fff;
    }




/* PRODUCTS ************************************/
section.product_table{
    width: 100%;
    padding: 0 0 3rem 0;
}
    .product_category {
        text-align: center;
        width: 100%;
        padding: 0 0 1rem 0;
    }
        .product_category h1,
        .product_category img {
            display: inline-block;
        }
        .product_category img {
            height: 40px;
            width: auto;
        }
    .product_availability {
    	text-align: center;
    	padding: .5rem 1rem 1.5rem;
    }
    	.product_availability h4 {
    		color: #3A455F;
    		font-size: 1.375rem;
    	}
    	.product_availability h4 a {
    		color: #20283A;
    	}
        .p_row{
            width: 100%;
            background: #fff;
            box-shadow: 0px 0px 2px rgba(0,0,0,.3);
            padding: .75rem;
            border-radius: .5rem;
            
            margin: 0 0 1rem;
        }
            .p_col {
                display: inline-block;
                vertical-align: top;
                width: 32%;
            }
                    .p_cell{
                        padding: .25rem 1rem .5rem 1.75rem;
                        margin: 0 0 0;
                        /*border: 1px solid #efefef;
                        font-size: .825rem;*/
                    }
                    .p_cell:before {
                        content: attr(name) "";
                        font-size: .6125rem;
                        display: block;
                        font-family: verdana, sans-serif;
                        text-transform: uppercase;
                        color: #999;
                        /*padding-bottom: .2rem;*/
                        margin-left: -1rem;            
                    }
                        .p_attr:before {
                            content: attr(name) "";
                            font-size: .6125rem;
                            display: inline-block;
                            font-family: verdana, sans-serif;
                            text-transform: uppercase;
                            color: #999;
                            border-left: 2px solid #ddd;
                            padding: .35rem 0 .35rem .5rem;
                            margin: 0;
                        }
                            .p_performance .p_attr:before {
                                min-width: 45%;
                            }
                            .p_ultrasound .p_attr:before {
                                min-width: 25%;
                            }
                        .p_attr.p_birth_weight:after,
                        .p_attr.p_weaning_weight:after,
                        .p_attr.p_yearling_weight:after {
                            content: " lbs";
                            font-size: .675rem;
                            display: inline-block;
                            font-family: verdana, sans-serif;
                            text-transform: uppercase;
                            color: #999;
                            padding-bottom: .2rem;      
                        }
               .p_img {
	               	text-align: center;
	               	position: relative;
	               	z-index: 100;
               }
               		.p_img.nla img {
               			filter: saturate(0%) opacity(50%);
               		}
.p_img.nla:before {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.5);
    display: flex; /* Enables flexbox */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    content: 'no longer available';
    color: rgba(255, 255, 255, 0.35);
    font-size: 1rem; /* Adjust text size as needed */
    font-weight: 500;
    font-style: italic;

}
                   	.p_img img {
                        width: 100%;
                        height: auto;
                        border-radius: 3px;
                        
                        /* parallax shrink and sticky header on scroll */
                        -webkit-transition: all 0.1s ease-in-out;
                        -moz-transition: all 0.1s ease-in-out;
                        -o-transition: all 0.1s ease-in-out;
                        transition: all 0.1s ease-in-out;
                   }
                    .p_img a {
                        border: 0;
                        text-decoration: none;
                        font-size: .675rem;
                        text-align: center;
                        color: #666;
                        position: relative;
                        display: block;
                    }
                        .p_img a:hover img {
                            opacity: .9;
                        }
                    .img_click {
                        background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
                        bottom: 0;
                        color: #fff;
                        margin: 0 auto;
                        padding: 0.5rem 0;
                        position: absolute;
                        text-align: center;
                        width: 100%;
                        border-bottom-left-radius: 3px;
                        border-bottom-right-radius: 3px;
                    }

                    .p_img a .img_click {
                        line-height: 1;
                        font-size: 10px;

                        /* parallax shrink and sticky header on scroll */
                        -webkit-transition: all 0.1s ease-in-out;
                        -moz-transition: all 0.1s ease-in-out;
                        -o-transition: all 0.1s ease-in-out;
                        transition: all 0.1s ease-in-out;
                    }
                        .p_img a:hover .img_click {
                            line-height: 2;
                            font-size: 12px;
                        }
               .p_info{
                }
                    .p_name{
                        font-size: 120%;
                        font-weight: 500;
                    }
                    .p_sire{
                    }
                    .p_dam{
                    }
                .p_avail{
                }
                    .p_embryo{
                    }
                    .p_eggs{
                    }
                    .p_semen{
                    }
                    .p_type{
                    }
                    .p_born{
                    }

        @media (max-width: 850px){
            .hide_mobile {
                display: none !important;
            }
        }

        @media screen and (min-width: 200px) and (max-width: 599px){
            .p_col {
                width: 100%;
                display: block;
                padding-bottom: 1rem;
            }
                .p_info,
                .p_avail {
                    padding: 0 1rem;
                }

            .p_cell{
                padding: .25rem .3rem .5rem .3rem;                
            }
      }
        @media screen and (min-width: 600px) and (max-width: 850px){
            .p_col {
                width: 49%;
                display: inline-block;
            }
            .p_col.p_img {
                display: block;
                clear: both;
                width: 100%;
                margin: 0 auto;
                padding-bottom: 1.5rem;
                position: relative;
            }


            .embryo .p_col,
            .embryo .p_col.p_img,
            .semen .p_col,
            .semen .p_col.p_img {
                width: 32%;
                display: inline-block;
            }
        }

      /****** image popover fade effect ***********/
        /* overlay at start */
        .mfp-fade.mfp-bg {
          opacity: 0;

          -webkit-transition: all 0.15s ease-out;
          -moz-transition: all 0.15s ease-out;
          transition: all 0.15s ease-out;
        }
        /* overlay animate in */
        .mfp-fade.mfp-bg.mfp-ready {
          opacity: 0.8;
        }
        /* overlay animate out */
        .mfp-fade.mfp-bg.mfp-removing {
          opacity: 0;
        }

        /* content at start */
        .mfp-fade.mfp-wrap .mfp-content {
          opacity: 0;

          -webkit-transition: all 0.15s ease-out;
          -moz-transition: all 0.15s ease-out;
          transition: all 0.15s ease-out;
        }
        /* content animate it */
        .mfp-fade.mfp-wrap.mfp-ready .mfp-content {
          opacity: 1;
        }
        /* content animate out */
        .mfp-fade.mfp-wrap.mfp-removing .mfp-content {
          opacity: 0;
        }

        
/* PRINTABLE PRODUCTS ************************************/
#print_logo,
#print_content,
#print_footer,
#print_copyright {
    width: 100%;
    padding: 1rem 0rem;
}
#print_content #print_logo {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
}
#print_content {
    padding-top: 120px;
}
#print_content .product_table .p_right .p_row {
    border-radius: 0 !important;
    box-shadow: none !important;
}
    #print_content .p_cell:before {
        content: attr(name) ":";
        font-size: .675rem;
        display: block;
        font-family: verdana, sans-serif;
        font-weight: bold;
        text-transform: uppercase;
        color: rgba(185,185,185,.9);
        padding-bottom: .2rem;                  
    }


#print_footer {
    text-align: center;
    border-top: 2px solid #ccc;
    
}
    #print_copyright {
        font-size: .75rem;
    }








/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: auto;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 1rem 1rem;
    border-radius: 6px;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 125%;
    /* left: 50%;
    margin-left: -60px;*/
    right: -480%;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}



.tool_heading {
    display: block;
    padding: 0 0 .5rem;
}
.tool_t {
    background-color: #efefef;
    border: 0;
    border-collapse: collapse;
    
    padding: .5rem;
    border-radius: 5px;
    overflow: hidden;
    font-size: .75rem;
    line-height: 1;
}
    .tool_t th {
        background: #c90000;
        color: #fff;
        padding: .75rem 1.5rem .5rem;
        white-space: nowrap;
    }
        .tool_t td {
            background: #777;
            color: #fff;
            padding: .5rem 1rem;
            white-space: nowrap;

        }